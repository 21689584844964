import React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import AppRoutes from 'src/routes/appRoutes';
import Breadcrumb from 'src/components/Breadcrumb';
import CrudPage from 'src/components/crud/CrudPage';

import { Column } from 'src/components/crud/Crud.d';
import { Place } from './Place.d';

export const name = {
    singular: 'Local',
    plural: 'Locais',
};

export const columns = [
    {
        accessorKey: 'integration_id',
        header: 'ID de Integração',
        filterLookup: 'exact',
    },
    {
        accessorKey: 'cnpj',
        header: 'CNPJ',
    },
    {
        accessorKey: 'description',
        header: 'Descrição',
    },
    {
        accessorKey: 'latitude',
        header: 'Latitude',
        hideColumn: true,
    },
    {
        accessorKey: 'longitude',
        header: 'Longitude',
        hideColumn: true,
    },
    {
        accessorKey: 'is_allowed_overnight',
        header: 'Permite pernoite',
        hideColumn: true,
        field: {
            type: 'check',
        },
    },
    {
        accessorKey: 'is_allowed_refuel',
        header: 'Permite abastecimento',
        hideColumn: true,
        field: {
            type: 'check',
        },
    },
    {
        accessorKey: 'is_active',
        header: 'Ativo',
        hideColumn: true,
        field: {
            type: 'check',
        },
    },
] as Column<Place>[];

export const endpoint = '/api/locations/places/';

const PlacesPage = () => {
    return (
        <>
            <Stack direction={'row'} justifyContent={'space-between'} pt={4}>
                <Box mb={1}>
                    <Breadcrumb items={['Cadastros', name.plural]} />
                </Box>
            </Stack>

            <CrudPage name={name} endpoint={endpoint} route={AppRoutes.Vehicles} columns={columns} />
        </>
    );
};

export default PlacesPage;
